export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    action: "read",
    resource: "dashboard" ,
    route: 'dashboard-moa',
  }, 
]
