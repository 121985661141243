export default [
    {
      header: 'Collaboration',
      action: "read",
      resource: "social"    
    },
    {
      title: 'Tâches',
      route: 'collaboration-todo',
      icon: 'CheckSquareIcon',
      action: "read",
      resource: "social"
    },  
    {
      title: 'Messages',
      route: 'collaboration-email',
      icon: 'MailIcon',
      action: "read",
      resource: "social"    
    },
    {
      title: 'Contacts',
      icon: 'UserIcon',
      route: 'collaboration-users-list',
      action: "read",
      resource: "social"    
   /*   children: [
        {
          title: 'List',
          route: 'apps-users-list',
        },
        {
          title: 'View',
          route: { name: 'apps-users-view', params: { id: 21 } },
        },
        {
          title: 'Edit',
          route: { name: 'apps-users-edit', params: { id: 21 } },
        },
      ],*/
    },
]