export default [
    {
        header: 'Administration',
      },
      {
        title: 'Utilisateurs',
        icon: 'UsersIcon',
      }, 
      {
        title: 'Workflow / Rôles',
        icon: 'SlidersIcon',
      }, 
      {
        title: 'Reporting',
        icon: 'ColumnsIcon',
      },  
      {
        title: 'API / Connecteurs',
        icon: 'LinkIcon',
      },     
      {
        title: 'Référentiels',
        icon: 'DatabaseIcon',
        children: [
          {
            title: 'Tiers partie',
          }, 
        
          {
            title: 'Type d\'inspection',
            route: 'apps-inspection-configtype',
          },
        ]  
      },   

]